import React, { useMemo } from 'react';
import Select from 'react-select'
import styled from 'styled-components';
import { MdPlayArrow } from 'react-icons/md'
import themeGet from '@styled-system/theme-get'
import { Field, FastField } from 'formik'
// import get from 'lodash/get'

import { responsive } from 'components/ThemeProvider/theme';
import useResponsive from 'contexts/mediaQuery/useResponsive'
import Box from './Box'
import Flex from './Flex'
import Label from './Label'
import InputError from './InputError'

const padding = '0.25rem'

const StyledDD = styled(Select)`
  font-size: 1.125em;
  text-align: center;
  .react-select__value-container {
    padding: 0.25em 0.5em;
  }

  .css-1uccc91-singleValue {
    left: 40%;
    transform: translate(-50%, -50%);
  }

  .react-select__control {
    border: none;
    outline: none;
    border-radius: 0;
    border-bottom: 2px solid lightGray;

    .react-select__placeholder  {
      color: lightgray;
      font-size: 0.875rem;
      left: 45%;
      transform: translate(-50%, -50%);
    }
    .react-select__dropdown-indicator {
      display: none;
    }
    &:hover {
    }
    &.react-select__control--menu-is-open {
      background-color: white;
      color: black;
      .react-select__placeholder  {
        color: white;
      }
      .react-select__single-value {
        color: white;
      }
      .react-select__dropdown-indicator {
        color: white;
      }
    }
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__menu {
    margin: 0;
    border-radius: 0;
    border: 1px solid ${themeGet('colors.purple')};
    z-index: 5;
  }

  .react-select__menuList {
    padding: 0;
  }

  .react-select__option {
    color: black;
    margin-left: auto;
    padding: 0.5em 1em;
    border-bottom: none;
    &.react-select__option--is-focused,
    &.react-select__option--is-selected {
      color: black;
      background: ${themeGet('colors.lightPurple')};
    }
  }
`

const StyledSelect = styled(Box)`
  appearance: none;
  border: none;
  outline: none;
`;

const arrow = (
  <Box
    position="absolute"
    color="darkPurple"
    lineHeight="0"
    right="0"
    top="50%"
    transform="translateY(-50%) rotate(90deg)"
    pointerEvents="none"
    fontSize={responsive('0.75em', '1em')}
  >
    <MdPlayArrow size="1.5em" />
  </Box>
)

const DD = ({
  options,
  name,
  onChange,
  onBlur,
  value,
  number,
  placeholder,
  disabled,
  readOnly,
  preEle,
  thin,
  zIndex,
  defaultInputValue,
  error,
  label,
  ...props
}) => {
  const d = disabled || options.length === 0
  const goodOptions = useMemo(() => {
    if (typeof options[0] === 'string' || typeof options[0] === 'number') {
      return [{ label: placeholder, value: '' }, ...options.map(opt => ({ label: opt, value: opt }))]
    }
    return [{ label: placeholder, value: '' }, ...options]
  }, [options, placeholder])
  const selectedOption = useMemo(() => {
    return goodOptions.find((opt) => opt.value == value) || null
  }, [value, goodOptions])
  const { isMobile } = useResponsive()
  return (
    <Flex position="relative" {...props}>
      {label && <Label>{label}</Label>}
      {isMobile ? (
        <Box flex={1} textAlign="center" borderBottom="1px solid black" position="relative">
          <StyledSelect
            as="select"
            width="100%"
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            py={padding}
            px={padding}
            fontSize="1.5em"
            bg="transparent"
            borderRadius="0"
            isDisabled={d}
            readOnly={readOnly}
          >
            {placeholder && <option value="_">{placeholder}</option>}
            {goodOptions.map((option) => {
              const v = option.value
              const l = option.label
              return (
                <option key={v} value={v}>
                  {l}
                </option>
              )}
            )}
          </StyledSelect>
          {arrow}
        </Box>
      ) : (
        <Box flex={1} position="relative" zIndex={zIndex}>
          <StyledDD
            placeholder={placeholder}
            onChange={(option) => onChange({
              target: {
                value: option.value,
                name,
              },
              persist: () => {},
            })}
            value={selectedOption}
            options={goodOptions}
            isDisabled={d}
            readOnly={readOnly}
            classNamePrefix="react-select"
            defaultInputValue={defaultInputValue}
          />
          {arrow}
        </Box>
      )}
      <InputError error={error} />
    </Flex>
  );
}

DD.defaultProps = {
  onChange: () => {},
  placeholder: '---',
}

DD.Field = ({ name, ...props }) => (
  <Field name={name}>
    {({ field, meta }) => (
      <DD
        {...props}
        {...field}
        error={meta.touched && meta.error}
      />
    )}
  </Field>
)

DD.FastField = ({ name, ...props }) => (
  <FastField name={name}>
    {({ field, meta }) => (
      <DD
        {...props}
        {...field}
        error={meta.touched && meta.error}
      />
    )}
  </FastField>
)

export default DD
