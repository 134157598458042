import React from 'react'

import Box from 'components/Box'
import RequiredMark from '../containers/ApplyPage/RequiredMark'
// import { responsive } from 'components/ThemeProvider/theme';

const Label = ({ children, labelWidth, isRequired, ...props }) => {
  return (
    <Box
      mr="1em"
      width={labelWidth}
      color="darkPurple"
      fontSize="1.125em"
      fontWeight="bold"
      {...props}
    >
      {isRequired && <RequiredMark />}
      {children}
    </Box>
  )
}

export default Label
