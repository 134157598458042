import React from 'react'
import Text from 'components/Text'

const RequiredMark = () => {
  return (
    <Text as="span" color="red" fontSize="1.375em" lineHeight="1rem">
      *
    </Text>
  )
}

export default RequiredMark
